<template>
  <div>
    <h1>能耗报表</h1>
  </div>
</template>

<script>

export default {
  name: "energy",
  mounted() {
    const category = this.$route.params.category;
    switch (category) {
      case 'energy':
        this.$emit('selectMenu', this.$route.path, '能耗报表');
        break;
    }
  }
}
</script>

<style scoped>

</style>
